import * as React from "react"
import HomeLayout from "../components/HomeLayout"
import { Box, Link, Text } from "@chakra-ui/react"

const IoCoopPage = (): JSX.Element => {
  return (
    <HomeLayout pageTitle={"io coöperative"}>
      <Box mt={5}>
        <Text py={3}>
          Not long after arriving in the SF Bay Area in the early 2000s, I found
          myself in need of a datacenter to house a basic rack-mount server. One
          of my colleagues at the time put me in touch with the California
          Community Colocation Project, which offered donation-based colocation
          services to individuals and nonprofits.
        </Text>
        <Text py={3}>
          Over time, the donation-based business model of CCCP would prove to be
          financially unsustainable and would ultimately doom the endeavor. For
          a time, the remains of the group operated under a loosely organized
          association calling itself Cernio, but without an established
          structure, this too failed.
        </Text>
        <Text py={3}>
          I co-founded <Link href={"https://iocoop.org"}>IO Coöperative</Link>{" "}
          in 2012 with members of this community, forming a California consumer
          coöperative to govern our affairs, and serving as the Board Chair and
          Secretary for three years. The coöperative is still going strong, and
          I continue to volunteer to both governance and operations efforts.
        </Text>
      </Box>
    </HomeLayout>
  )
}

export default IoCoopPage
